<template>
  <svg id="Regular" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>real-estate-person-search-house</title>
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M12.75,15.75h3v4.5l4.5-4.5h1.494a1.506,1.506,0,0,0,1.506-1.506V2.25a1.5,1.5,0,0,0-1.5-1.5h-12a1.5,1.5,0,0,0-1.5,1.5v4.5"
    />
    <circle fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" cx="6.75" cy="13.125" r="3.375" />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M12.75,23.25a6.054,6.054,0,0,0-12,0"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M18.75,6.25v5A.75.75,0,0,1,18,12H13.5a.75.75,0,0,1-.75-.75v-5"
    />
    <polyline
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      points="11.25 7.5 15.75 3.75 20.25 7.5"
    />
  </svg>
</template>
